<template>
    <div>
        <input
            type="text"
            id="textBox"
            ref="textBox"
            v-model="inputText"
            :class="{ lightUp: isExxy }"
            autocomplete="off"
        />
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: "Home",
    data() {
        return {
            inputText: "hello there.",
        };
    },

    mounted() {
        this.$refs.textBox.focus();
    },

    computed: {
        isExxy() {
            return this.inputText == "exxy";
        },
    },
};
</script>

<style lang="scss" scoped>
#textBox {
    width: 100%;
    padding: 20px 0;
}

input {
    -webkit-appearance: none;
    outline: none;
    background-color: transparent;
    color: inherit;
    border: none;
    font-size: inherit;
    text-align: center;
    border: 1px solid transparent;
}

input.lightUp {
    border: 1px solid yellow;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
</style>
